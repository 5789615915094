import React, { FC } from 'react';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { SimilarBrand } from '../types';
import { Skeleton } from 'antd';
import { getInitials } from 'utils/getInitialsBrandName';
import Link from 'components/Link/Link';
import { generateUrlBrandDetail } from '../../../utils/generateBrandDetailUrl';

const Container = styled.div`
  margin-top: 40px;
  padding-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
`;

const LogoContainer = styled.div`
  margin-top: 20px;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px;

  ${media.sm`
    grid-template-columns: repeat(5, minmax(0, 1fr));  
  `};

  ${media.md`
    grid-template-columns: repeat(6, minmax(0, 1fr));  
    column-gap: 16px;
    row-gap: 16px;
  `};

  ${media.lg`
    grid-template-columns: repeat(8, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 20px;
 `};
`;

const LogoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #eaeaeb;
  cursor: pointer;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #242527;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  ${media.md`
    font-size: 20px;
  `}
`;

const Logo = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

interface IProps {
  similarBrand: SimilarBrand[];
}

const SimilarBrand: FC<IProps> = ({ similarBrand }: IProps) => {


  return (
    <Container>
      <Title>
        <FormattedMessage {...messages.similarBrands} defaultMessage="Thương hiệu tương tự" />
      </Title>
      <LogoContainer>
        {similarBrand?.map((brand) =>
          brand.loading ? (
            <Skeleton.Image active={true} key={brand.id} />
          ) : (
            <Link to={generateUrlBrandDetail(brand.id, brand?.name as string)} key={brand.id}>
              <LogoWrapper
              >
                {brand?.imageUrl ? <Logo src={brand.imageUrl} loading="lazy" /> : getInitials(brand?.name as string)}
              </LogoWrapper>
            </Link>
          ),
        )}
      </LogoContainer>
    </Container>
  );
};


export default SimilarBrand;
