import React, { FC, Fragment } from 'react';
import { Spin } from 'antd';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import injectReducer from 'utils/injectReducer';
import reducer, { loadingResources } from './reducer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { ContainerState } from './types';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import styled from 'styles/styled-components';
import selectBrandDetailPage from './selectors';
import BrandInfo from './components/BrandInfo';
import media from 'utils/mediaStyle';
import ProductList from './components/ProductList';
import SimilarBrand from './components/SimilarBrand';
import GroupBrandProductList from './components/GroupBrandProductList';

const MainContainer = styled.div`
  display: flex;
  background: #ffffff;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;

  ${media.md`
    background: none;
    padding-left: 0;
    padding-right: 0;
  `};
`;

export interface IStateProps {
  brandDetailDetail: ContainerState;
}
type Props = IStateProps & RouteComponentProps;
const BrandDetailPage: FC<Props> = (props) => {
  const {
    brandDetailDetail: { brandInfo, groupBrands, loading, similarBrands },
  } = props;

  return (
    <Fragment>
      <Helmet>
        <title>Thương hiệu {brandInfo?.name}</title>
        <meta name="description" content={`Thương hiệu ${brandInfo?.name} - ${brandInfo?.description}`} />
        <meta property="og:title" content={`Thương hiệu ${brandInfo?.name}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Spin spinning={loading}>
        <MainContainer>
          <BrandInfo brandInfo={brandInfo} groupBrands={groupBrands} />
          <ProductList sectionName={brandInfo.id} products={brandInfo.id ? groupBrands[brandInfo.id].data : loadingResources} totalResults={groupBrands[brandInfo.id]?.totalResults} />
          {brandInfo.groupName && Object.keys(groupBrands).length > 1 && <GroupBrandProductList brandId={brandInfo.id} groupBrands={groupBrands} groupName={brandInfo.groupName} />}
          {similarBrands?.length > 0 && <SimilarBrand similarBrand={similarBrands} />}
        </MainContainer>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  brandDetailDetail: selectBrandDetailPage(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({
  key: 'brandDetailPage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'brandDetailPage', saga: saga });

export default compose(withConnect, withReducer, withSaga)(BrandDetailPage);
